import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { supabase } from '../firebase'
import { UserAuth } from '../context/AuthContext'


const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate();
  const { logout, session } = UserAuth();
  

  useEffect(() => {
    const handleLogout = async () => {
      if (session) {
       await supabase.auth.signOut()
      }
      navigate('/'); // Ensure redirection to the login page after logout
    };

    handleLogout();
  }, [logout, session, navigate]);

  const handleSubmit = async (e) => {
    setError('')
    e.preventDefault()

    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    })
    if (data?.user) { navigate('/account'); }
    else {
      setError('Invalid Credentials')
      console.log(error)
    }
  }
  return (
    <>
      <div className='w-full'>
        <div className='bg-white w-full'></div>
        <div className=' w-full px-3 py-20 z-50'>
          <div className='max-w-[400px] h-auto mx-auto bg-white text-white'>
            <div className='max-w-[320px] mx-auto py-14'>
              <h1 className='text-3xl font-bold text-blue-600'>Log In</h1>
              {error ? <p className='p-3 bg-red-400 my-2'>{error}</p> : <></>}
              <form className='w-full flex flex-col py-4' onSubmit={handleSubmit} >
                <input className='p-3 my-2 bg-gray-700 rounded' type='email' placeholder='Email' autoComplete='email' onChange={(e) => { setEmail(e.target.value); setError('') }} />
                <input className='p-3 my-2 bg-gray-700 rounded' type="password" placeholder='Password' autoComplete='current-password' onChange={(e) => { setPassword(e.target.value) }} />
                <motion.button whileTap={{ scale: 0.6 }} className='bg-blue-600 py-3 my-4 rounded font-bold'>Log In</motion.button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login