import React from 'react'
import { useEffect, useState } from 'react'
import { UserAuth } from '../context/AuthContext';
import { supabase } from '../firebase'
const adminemail = process.env.REACT_APP_ADMIN1_EMAIL
const Form = () => {
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState('')
    const [id, setId] = useState([])
    const [courses, setCourses] = useState({});
    const [data, setData] = useState({});
    const [selectedCourse, setSelectedCourse] = useState('')
    const { session } = UserAuth();

    const admin = () => {
        if (session?.user?.email === adminemail) {
            return true;
        }
        return false
    }



    useEffect(() => {
        if (admin()) {
            const fetchedUsers = [];
            const fetchedCourses = []
            const fetchDoc = async () => {

                const { data, error } = await supabase
                    .from('creators')
                    .select()
                if (data) {
                    setData(data);
                    data.forEach((item) => !fetchedUsers.includes(item.email) && fetchedUsers.push(item.email))

                    setUsers(fetchedUsers);
                    setSelectedUser(fetchedUsers[0])
                    data.forEach((item) => item.email === data[0].email && fetchedCourses.push(item.course))
                    setId(fetchedCourses);
                    setCourses(data[0]);
                    setSelectedCourse(data[0].course)
                }
                else {
                    alert(error.message);
                }

            }
            fetchDoc();
        }
        else {
            const fetchedCourses = [];
            const fetchDoc = async () => {

                const { data, error } = await supabase
                    .from('creators')
                    .select()
                    .eq('email', `${session?.user.email}`)

                if (data) {
                    setData(data);
                    data.forEach((item) => fetchedCourses.push(item.course))
                    setId(fetchedCourses);
                    setCourses(data[0]);
                    setSelectedCourse(fetchedCourses[0])
                }
                else {
                    alert(error.message);
                }
            }
            fetchDoc();
        }
        console.log('rendered')
    }, [session?.user?.email])
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formObject = {};
      

        formData.forEach((value, key) => {
            formObject[key] = value;
        });
        
        const { error } = await supabase
            .from('creators')
            .update(formObject)
            .eq('email', `${admin() ? selectedUser : session?.user?.email}`).eq('course', `${selectedCourse}`)

        if (!error) {
            alert('updated Successfully')
        }
        else {
            alert(error.message);
        }

    }
   
    const handleUserSelect = (e) => {
        setSelectedCourse(null);
        const email = e.target.value;
        setSelectedUser(email);
        let newCourses = [];
        data.forEach((item) => item.email === email && newCourses.push(item.course))
        setId(newCourses)
        setSelectedCourse(newCourses[0]);
        setCourses(data?.filter((item) => item.email === email)[0]);
        
    }
   
    const handleSelect = (e) => {
        setSelectedCourse(e.target.value);
        if(admin())
        {setCourses(data?.filter((item) => item.email === selectedUser && item.course === e.target.value)[0]);}
        else{
            setCourses(data?.filter((item) => item.course === e.target.value)[0]);
        }
       
    }
    
    return (
        <>
            <section class="flex flex-col items-center justify-center gap-4">

                <div
                    class="w-full max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ">
                    {admin() && <div class="mb-6">
                        <label for="users" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Users</label>
                        <select id="users" defaultValue={selectedUser} value={selectedUser} name='users' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleUserSelect}>
                            {users?.map((item, i) => {
                                return <option id={i} value={item}>{item}</option>
                            })}
                        </select>
                    </div>}
                    <div class="mb-6">
                        <label for="courses" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Courses</label>
                        <select id="courses" defaultValue={selectedCourse} value={selectedCourse} name='courses' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleSelect}>
                            {id?.map((item, i) => {
                                return <option id={i} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                    <div class="mb-6">
                        <a className='text-blue-600' href={courses?.url}>{courses?.url}</a>
                    </div>

                    <form onSubmit={handleSubmit}>
                        {courses?.cta && <div class="mb-6">
                            <label for="cta" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New CTA 1 </label>
                            <input type="url" defaultValue={courses?.cta} key={courses?.cta} id="cta" placeholder="5000" name='cta'
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>}
                        {courses?.cta1 && <div class="mb-6">
                            <label for="cta1" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New CTA 2 </label>
                            <input type="url" defaultValue={courses?.cta1} key={courses?.cta1} id="cta1" placeholder="5000" name='cta1'
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>}
                        {courses?.whatsapp && <div class="mb-6">
                            <label for="whatsapp" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Whatsapp Link  </label>
                            <input type="url" defaultValue={courses?.whatsapp} key={courses?.whatsapp} id="whatsapp" placeholder="5000" name="whatsapp"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>}


                        {courses?.time && <div class="mb-6">
                            <label for="time" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New End Time - HH:MM</label>
                            <input id="time" defaultValue={courses?.time} key={courses?.time} type="time" name="time" required class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>}

                        {courses?.date &&  <> <label for="date" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New End Date - DD/MM/YYYY </label>
                        <div class="relative mb-6 max-w-sm">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                </svg>
                            </div>
                                <input datepicker type="date" name="date" key={courses?.date} id="date" defaultValue={courses?.date} required class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="01//01/2000" />
                            </div></>}
                        {/* <Datepicker type='text' defaultValue={data?.date} placeholder='Date' /> */}


                        {courses?.price &&   <div class="mb-6">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Price - (If required) </label>
                            <input type="number" defaultValue={courses?.price} key={courses?.price} id="price" placeholder="5000" name="price"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>}
                        {courses?.cut_price && <div class="mb-6">
                            <label for="cut_price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Cut Price - (If required) </label>
                            <input type="number" defaultValue={courses?.cut_price} key={courses?.cut_price} id="cut_price" placeholder="5000" name="cut_price"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>}
                        {courses?.discount && <div class="mb-6">
                            <label for="discount" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Discount (₹)/(%) - (If required) </label>
                            <input type="text" defaultValue={courses?.discount} key={courses?.discount} id="discount" placeholder="5000" name="discount"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>}
                        <button type="submit"
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                    </form>
                </div>
            </section>
        </>
    )
}

export default Form