import { createContext,useContext,useEffect,useState } from "react";
import {supabase} from '../firebase'



const AuthContext=createContext();
export function AuthContextProvider({children}){
const [session,setSession]=useState({});


useEffect(()=>{
    const subscription = supabase.auth.onAuthStateChange(
        (event, session) => {
            if (event === 'SIGNED_OUT') {
                setSession(null)
            } else if (session) {
                setSession(session)
            }
        })

    return () => {
        subscription.unsubscribe()
    }
}, [])

    return(
        <AuthContext.Provider value={{session}}>
            {children}
        </AuthContext.Provider>
    )
}

export function UserAuth(){
    return useContext(AuthContext);
}