import React from 'react'
import Forms from '../components/Forms'

const Account = () => {
  return (
    <>
      <div className='w-full text-white'>
      </div>
      <Forms />
    </>

  )
}

export default Account