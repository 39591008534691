import Navbar from "./components/Navbar";
import { Routes ,Route, useNavigate} from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import Login from "./pages/Login";
import Account from "./pages/Account";
import ProtectedRoute from "./components/ProtectedRoute";
import ResetPassword from "./pages/ResetPassword";
import { useEffect } from "react";
import { supabase } from './firebase'

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "PASSWORD_RECOVERY") {
         navigate('/resetpassword')
      }
    })
  },[navigate])
  
  return (
    <>
    <AuthContextProvider>
    <Navbar/>
     <Routes>
      <Route path='/' element={<Login/>}/>
      <Route path='/resetpassword' element={<ResetPassword />} />
      <Route path='/account' element={ <ProtectedRoute><Account/></ProtectedRoute>}/>
      
     </Routes>
    
    </AuthContextProvider>
     
    </>
  );
}

export default App;
